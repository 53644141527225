import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import Loader from '../../components/Loader';

import './style.scss';

const FebruaryWebinarPage = () => {
    useEffect(() => {});

    return (
        <Layout navTheme="light">
            <Seo title="February 2021 Webinar" />
            <div className="february-2021-webinar-page">
                <Container className="hero-container">
                    <div className="background"></div>
                    <Row>
                        <Col>
                            <h1 className="white-text">Webinar Registration</h1>
                        </Col>
                    </Row>
                </Container>
                <Container className="webinar-detail-container">
                    <Row>
                        <Col xs md="3" className="label-col">
                            Topic:
                        </Col>
                        <Col md="auto">
                            <p>
                                The Biggest Breakthrough in IT Marketing and
                                Sales Automation Ever!
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs md="3" className="label-col">
                            Brief:
                        </Col>
                        <Col md="auto">
                            <p>
                                A new year is among us, a fresh start to plan
                                and overcome the impact that 2020 and the
                                pandemic made on our businesses across our great
                                country. Social distancing, remote working, and
                                lockdowns remain. This makes planning for growth
                                in 2021 that much more difficult. We need new
                                ways to market and sell digitally so that we can
                                continue to find success and grow this year. How
                                do you find time, budget, content, and resources
                                to execute consistent marketing and sales? How
                                do you keep an eye on performance so you can
                                make the right decisions? Join us as we answer
                                all of this and unveil our formula for helping
                                MSPs generate over 100,000 leads, 32,000
                                meetings, and helped MSPs generate over
                                $800,000,000 in new revenue.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs md="3" className="label-col">
                            Time:
                        </Col>
                        <Col md="auto">
                            <p>
                                February 11, 2021 11:00 AM Pacific Time, 1:00 PM
                                Central Time, 2:00 PM Eastern Time
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="form-container white-text">
                    <Row>
                        <Col>
                            <form
                                id="webinar-form"
                                className="webinar-form"
                                method="POST"
                                name="Webinar Form"
                                action="/february-2021-webinar/#thanks"
                                encType="multipart/form-data"
                                netlify="true"
                                netlify-honeypot="bot-field"
                                data-category="Webinar"
                                onSubmit={e => {
                                    e.preventDefault();
                                    const submitButton = document.getElementById(
                                        'sbmt-form-btn'
                                    );
                                    const loader = document.querySelector(
                                        '.loader'
                                    );
                                    const formName = document.getElementById(
                                        'webinar-form'
                                    );

                                    loader.style.display = 'block';
                                    submitButton.style.display = 'none';

                                    fetch(formName.getAttribute('action'), {
                                        method: 'POST',
                                        body: new FormData(
                                            document.getElementById(
                                                'webinar-form'
                                            )
                                        ),
                                    }).then(res => {
                                        if (res.status === 200) {
                                            document.querySelector(
                                                '#webinar-form'
                                            ).style.display = 'none';
                                            document.querySelector(
                                                '.contact-thank-you'
                                            ).style.display = 'flex';
                                        } else {
                                            loader.style.display = 'none';
                                            document.getElementById(
                                                'error-msg'
                                            ).style.display = 'block';
                                            submitButton.style.display =
                                                'block';
                                        }
                                    });
                                }}
                            >
                                <input
                                    type="hidden"
                                    name="bot-field"
                                    id="bot"
                                />
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="webinar-form"
                                />

                                <div className="field-row">
                                    <div className="field">
                                        <label htmlFor="name">Full Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="field-row">
                                    <div className="field">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="field-row">
                                    <div className="field">
                                        <label htmlFor="title">Job Title</label>
                                        <input
                                            type="text"
                                            name="title"
                                            id="title"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="field-row">
                                    <div className="field">
                                        <label htmlFor="company">
                                            Company Name
                                        </label>
                                        <input
                                            type="text"
                                            name="company"
                                            id="company"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="hide-me field">
                                    <input
                                        data-form-type="Registration"
                                        className="formcat"
                                        hidden
                                    ></input>
                                </div>

                                <div className="disclaimer">
                                    <p>
                                        By registering, I agree to the{' '}
                                        <a
                                            href="https://app.glasshive.com/Account/PrivacyPolicy"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href="https://app.glasshive.com/Account/TermsOfService"
                                            target="_blank"
                                        >
                                            Terms of Service
                                        </a>
                                        .
                                    </p>
                                </div>

                                <div className="submit-btn">
                                    <Loader />
                                    <p id="error-msg" style={{ color: 'red' }}>
                                        Looks like there was a problem
                                        submitting your form. Please ensure ALL
                                        form fields are filled out and try
                                        again.
                                    </p>
                                    <button
                                        id="sbmt-form-btn"
                                        type="submit"
                                        className="btn"
                                    >
                                        <span className="btn__mask"></span>
                                        <span className="btn__text">
                                            Register
                                        </span>
                                    </button>
                                </div>
                            </form>
                            <div
                                className="contact-thank-you reach"
                                id="thanks"
                            >
                                <h5>
                                    Thank you for registering! We'll send you a
                                    reminder in the coming days.
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default FebruaryWebinarPage;
